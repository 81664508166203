import React, { memo, useCallback, useEffect, useRef, useState } from "react"
import * as styles from "./animatedProcess.module.scss"

import Bulb from "../../assets/images/think.png"
import Puzzle from "../../assets/images/puzzle.png"
import Setting from "../../assets/images/setting.png"
import Settings from "../../assets/images/settings.png"
import Airplane from "../../assets/images/airplane.png"

const AnimatedProcess = () => {
  const [phase, setPhase] = useState(1)
  const timeout = useRef()

  const returnOpacity = useCallback(
    p => {
      if (p > phase) return 0
      return 1
      // return phase / 5
    },
    [phase]
  )
  const spin = useCallback(() => {
    timeout.current = setTimeout(() => {
      if (phase < 5) {
        setPhase(phase + 1)
      } else {
        setPhase(0)
      }
    }, 2000)
  }, [phase])

  useEffect(() => {
    spin()
  }, [spin])
  return (
    <div className={`${styles.container} dfr`}>
      <div
        style={{ opacity: returnOpacity(1) }}
        className={`${styles.box} bg_yellow`}
      >
        <img src={Bulb} alt="bulb" />
        <p>plan</p>
      </div>
      <div
        className={`${styles.middleLine} ${
          phase >= 1 ? styles.animatedLine : ""
        } bg_yellow`}
      ></div>
      <div
        style={{ opacity: returnOpacity(2) }}
        className={`${styles.box} bg_yellow`}
      >
        <img src={Puzzle} alt="puzzle" />
        <p>design</p>
      </div>
      <div
        className={`${styles.middleLine} ${
          phase >= 2 ? styles.animatedLine : ""
        } bg_yellow`}
      ></div>
      <div
        style={{ opacity: returnOpacity(3) }}
        className={`${styles.box} bg_yellow`}
      >
        <img src={Settings} alt="settings" />
        <p>implement</p>
      </div>
      <div
        className={`${styles.middleLine} ${
          phase >= 3 ? styles.animatedLine : ""
        } bg_yellow`}
      ></div>
      <div
        style={{ opacity: returnOpacity(4) }}
        className={`${styles.box} bg_yellow`}
      >
        <img src={Setting} alt="setting" />
        <p>test & review</p>
      </div>
      <div
        className={`${styles.middleLine} ${
          phase >= 4 ? styles.animatedLine : ""
        } bg_yellow`}
      ></div>
      <div
        style={{ opacity: returnOpacity(5) }}
        className={`${styles.box} bg_yellow`}
      >
        <img src={Airplane} alt="airplane" />
        <p>deliver</p>
      </div>
    </div>
  )
}

export default memo(AnimatedProcess)
