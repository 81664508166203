import * as React from "react"
import { navigate } from "gatsby"
import Markdown from "react-markdown"
import { useSelector, useDispatch } from "react-redux"
import { CSSTransition } from "react-transition-group"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Ideas from "../components/ideas/ideas"
import GlassParagraph from "../components/glassParagraph/glassParagraph"
import AnimatedProcess from "../components/animatedProcess/animatedProcess"
import Slider from "../components/slider/slider"
import YellowBorderButton from "../components/yellowBorderButton/YellowBorderButton"

import * as styles from "../assets/css/home.module.scss"

import { Strings } from "../constants/strings"
import colors from "../constants/colors"
import Images from "../constants/Images"

import { getMode } from "../redux/slices/modeSlice"
import { getSolutions } from "../redux/slices/solutionsSlice"
import { selectCustomers } from "../redux/slices/customerSlice"
import { selectHomepageEntities } from "../redux/slices/homePageSlice"

const __TIMEOUT__ = 50
let stringIndex = 0
let wordIndex = 0
let reverse = false
let tout
let interval

const IndexPage = () => {
  //REDUX STUFF
  const mode = useSelector(getMode)
  const entities = useSelector(selectHomepageEntities)
  const solutions = useSelector(getSolutions)
  //DATAS TO SHOW ON THE PAGE
  const [feature, setFeature] = React.useState("")
  const [activeCustomer, setActiveCustomer] = React.useState(null)
  const [services, setServices] = React.useState(0)

  const dispatch = useDispatch()
  const customers = useSelector(selectCustomers)

  const featureArrays = React.useMemo(() => {
    if (entities.entities)
      return entities.entities.animated_text_list
        ?.split(",")
        .map(elem => elem.trim())
  }, [entities])

  React.useEffect(() => {
    if (solutions.solutions.length) setServices(solutions.solutions[0].id)
  }, [solutions])

  const startFeatureBaraban = React.useCallback(() => {
    if (featureArrays && Array.isArray(featureArrays)) {
      let word = featureArrays[wordIndex]
      interval = setInterval(() => {
        setFeature(word.slice(0, stringIndex))
        if (reverse) {
          if (stringIndex === 0) {
            clearInterval(interval)
            tout = setTimeout(() => {
              if (wordIndex === featureArrays.length - 1) {
                wordIndex = 0
              } else {
                wordIndex++
              }
              stringIndex = 0
              reverse = false
              startFeatureBaraban()
            }, __TIMEOUT__)
          } else {
            stringIndex--
          }
        } else {
          if (stringIndex === word.length) {
            clearInterval(interval)
            tout = setTimeout(() => {
              reverse = true
              startFeatureBaraban()
            }, 20 * __TIMEOUT__)
          } else {
            stringIndex++
          }
        }
      }, __TIMEOUT__)
    }
  }, [featureArrays])

  React.useEffect(() => {
    startFeatureBaraban()
    return () => {
      clearInterval(interval)
      clearTimeout(tout)
    }
  }, [startFeatureBaraban])

  const image = React.useMemo(() => {
    if (entities.entities && entities.entities.header_image.data) {
      return (
        <img
          src={entities.entities.header_image.data.attributes.url}
          srcSet={`${Images.yellowButa} 400w,
                                                                              ${entities.entities.header_image.data.attributes.url}`}
          sizes="(max-width: 900px) 400px,"
          alt="developers"
        />
      )
    }
    return null
  }, [entities.entities])

  const selectedSolution = React.useMemo(() => {
    return solutions.solutions.find(elem => elem.id === services)
  }, [solutions, services])

  return (
    <Layout>
      <Seo title={`Home - ${Strings.companyName}`} />
      <div className={styles.brief_desc}>
        <div className={styles.description}>
          <h1 className={styles[`description_${mode}`]}>
            {`${entities?.entities?.animated_text_static_side} {${feature}}`};
          </h1>
          <p>
            {entities.entities && entities.entities.text_under_animated_title}
          </p>
          <YellowBorderButton
            onClick={() => navigate("/customer-idea-form")}
            title={Strings.getInTouch["en"]}
          />
        </div>
        {image}
      </div>
      <div className="disappear_on_mobile mbottom_4">
        <h2 className="mbottom_2">
          {entities.entities && entities.entities.process_title}
        </h2>
        <AnimatedProcess />
      </div>
      <GlassParagraph
        title={entities.entities && entities.entities.about_title}
        readMore="/about-us"
        paragraph={entities.entities && entities.entities.about_paragraph}
      />
      <h2 className="mbottom_1">
        {entities.entities && entities.entities.solutions_title}
      </h2>
      <div id="services" className={styles.services}>
        <div className={styles.services_buttons}>
          {solutions.solutions.map(elem => (
            <button
              key={elem.id}
              className={
                services === elem.id
                  ? styles.active_service +
                    " " +
                    styles[`active_service_${mode}`]
                  : ""
              }
              onClick={() => setServices(elem.id)}
            >
              <img
                alt={elem.attributes.name}
                style={{ width: "50px", height: "50px", objectFit: "cover" }}
                src={elem.attributes.image.data.attributes.url}
              />
              <div>
                <p> {elem.attributes.name}</p>
              </div>
            </button>
          ))}
        </div>
        <div className={styles.services_paragraph}>
          <GlassParagraph
            readMore={
              selectedSolution?.attributes.long_description
                ? `/solutions?active=${selectedSolution.id}`
                : null
            }
            butaImage={false}
          >
            <Markdown>{selectedSolution?.attributes.description}</Markdown>
          </GlassParagraph>
        </div>
      </div>
      <br />
      <div className="mbottom_5">
        <h2>{entities.entities && entities.entities.partners_title}</h2>
        <Slider arrowColor={colors.blue} viewAll={false} slidesToShow={4}>
          {customers.customers.map(({ attributes: elem, id }) => (
            <div
              role="button"
              onKeyDown={() => {}} //Due to ESLint warning
              tabIndex="0" //Due to ESLint warning
              onClick={() => setActiveCustomer(elem)}
              className={styles.client}
              key={id}
            >
              <div
                className={
                  styles.circle +
                  " " +
                  (elem.impression ? styles.client_active : "")
                }
              >
                <img src={elem.image.data.attributes.url} alt="client logo" />
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <CSSTransition
        timeout={500}
        unmountOnExit
        mountOnEnter
        classNames="opacity"
        in={!!activeCustomer && !!activeCustomer.description}
      >
        <GlassParagraph
          image={activeCustomer?.image?.data?.attributes?.url}
          name={activeCustomer?.name}
          readMore="/"
        >
          <Markdown>{activeCustomer?.description}</Markdown>
        </GlassParagraph>
      </CSSTransition>
      <Ideas form={true} title={Strings.haveAProjectInMind["en"]} />
    </Layout>
  )
}

export default IndexPage
