import { navigate } from "gatsby"
import React, { memo } from "react"
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io"
import { useSelector } from "react-redux"
import colors from "../../constants/colors"
import { Strings } from "../../constants/strings"
import { getLanguage } from "../../redux/slices/languageSlice"
import YellowBorderButton from "../yellowBorderButton/YellowBorderButton"
import * as styles from "./slider.module.scss"

const CustomSlider = ({
  children,
  viewAll = "/",
  arrowColor = colors.yellow,
}) => {
  const language = useSelector(getLanguage)
  const slider = React.useRef(null)
  const main = React.useRef(null)
  const [transition, setTransition] = React.useState(0)

  const onForward = () => {
    const mainWidth = main.current.clientWidth
    const sliderWidth = slider.current.clientWidth
    if (sliderWidth > mainWidth) {
      let transitionWidth = transition + slider.current.firstChild.clientWidth
      if (transitionWidth > sliderWidth - mainWidth) {
        transitionWidth = sliderWidth - mainWidth
      }
      setTransition(transitionWidth)
    }
  }

  const onBack = () => {
    if (transition > 0) {
      let transitionWidth = transition - slider.current.firstChild.clientWidth
      if (transitionWidth < 0) {
        transitionWidth = 0
      }
      setTransition(transitionWidth)
    }
  }

  return (
    <div className={styles.container}>
      <button onClick={onBack} className={styles.button}>
        <IoIosArrowBack color={arrowColor} />
      </button>
      <button onClick={onForward} className={styles.button}>
        <IoIosArrowForward color={arrowColor} />
      </button>
      <div ref={main} className={styles.main}>
        <div
          ref={slider}
          style={{ transform: `translateX(-${transition}px)` }}
          className={styles.slider}
        >
          {children}
        </div>
      </div>
      <br />
      {viewAll && (
        <YellowBorderButton
          onClick={() => navigate(viewAll)}
          title={Strings.viewAll[language]}
        />
      )}
    </div>
  )
}

export default memo(CustomSlider)
