// extracted by mini-css-extract-plugin
export var active_service = "home-module--active_service--u0jd8";
export var active_service_light = "home-module--active_service_light--WVAL5";
export var brief_desc = "home-module--brief_desc--stSTF";
export var circle = "home-module--circle--l9-Jy";
export var client = "home-module--client--UBIhv";
export var client_active = "home-module--client_active--rIS8a";
export var description = "home-module--description--LHSir";
export var description_dark = "home-module--description_dark--z9cQw";
export var description_light = "home-module--description_light--eX8IJ";
export var services = "home-module--services--gBhYf";
export var services_buttons = "home-module--services_buttons--E16VZ";
export var services_paragraph = "home-module--services_paragraph--lR6a7";